import React from 'react'
import { Seo } from '../../components/SEO.jsx'
import ThankYou from '../../components/ThankYou.jsx'

const index = () => {
  return (
    <>
    <Seo title="Thank you page" />
    <ThankYou />
    </>
  )
}

export default index