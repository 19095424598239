import React from 'react'
import Layout from './Layout'
import { Link } from 'gatsby'

const ThankYou = () => {
  return (
   <Layout>
         <main>
        <section className="bg-black relative z-10 py-[120px]">
          <div className="container mx-auto">
            <div className="flex">
              <div className="w-full">
                <div className="mx-auto max-w-[500px] text-center">
                  <h2 className="mb-2 text-[50px] font-bold leading-none text-white sm:text-[65px] md:text-[70px]">
                    Thank you for your purchase!
                  </h2>
                  <p className="mb-8 text-lg text-white">
                    You should receive a conformation email soon
                  </p>
                  <Link
                    to="/"
                    className="hover:text-primary inline-block rounded-lg border border-white px-8 py-3 text-center text-base font-semibold text-white transition hover:bg-white"
                  >
                    Go To Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-0 left-0 -z-10 flex h-full w-full items-center justify-between space-x-5 md:space-x-8 lg:space-x-14">
            <div className="h-full w-1/3 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]"></div>
            <div className="flex h-full w-1/3">
              <div className="h-full w-1/2 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]"></div>
              <div className="h-full w-1/2 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]"></div>
            </div>
            <div className="h-full w-1/3 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]"></div>
          </div>
        </section>
      </main>
   </Layout>
  )
}

export default ThankYou